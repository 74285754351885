<template>
    <div style="max-width: 800px; margin: auto;">
        <!-- Fonts import -->
        <link href="https://fonts.googleapis.com/css?family=Yellowtail" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=League+Gothic&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet">
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
        <!-- 1 card -->
        <div class="card text-center custom-text-000 mb-2" style="background-color: #0D4360;">
            <div class="row">
                <div class="col">
                    <a href="#logopaedie" style="color: white;">Logopädie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#physiotherapie" style="color: white;">Physiotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#ergotherapie" style="color: white;">Ergotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
            </div>
        </div>
        <!-- 2 card -->
        <div class="card text-center bg-primary mb-2">
            <div class="card-title custom-text-001">Das Viavitum</div>
            <div class="card-text text-end custom-text-002">...spürbar anders</div>
            <div class="card-text custom-text-01">mehr als nur ein Job</div>
            <div class="card-text custom-text-02">
                Spaß, Freude, Wertschätzung, und eine neue
                Herausforderung ist das, was du suchst?
            </div>
            <div class="card-text text-center custom-text-02">Dann bist du bei uns genau richtig!</div>
            <div class="text-center mb-3">
                <knowing-button />
                <!--
            <div class="input-group">
              <input type="email" class="form-control ml-3" v-model="email" placeholder="E-Mail-Addresse">
                <div class="input-group-append">
                  <button type="submit" class="btn btn-danger btn-sm mr-3" v-on:click="sendGettoknow">Jetzt kennen lernen!</button>
                </div>
            </div>
            -->
            </div>
            <div class="card-text custom-text-03 mb-2 p-3">
                <i class="fas fa-map-marker-alt"></i> 91575 Windsbach
            </div>
        </div>
        <!-- 1 card -->
        <div class="card text-center custom-text-000 mb-2" style="background-color: #AFC437;">
            <div class="row">
                <div class="col">
                    <a href="#logopaedie">Logopädie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#physiotherapie">Physiotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#ergotherapie">Ergotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
            </div>
        </div>
        <!-- logo card -->
        <div class="card mb-2">
            <img src="../assets/img/landing/logomitfotos.png" class="img-fluid">
        </div>
        <!-- -->
        <div class="card text-center custom-text-000 mb-2" style="background-color: #AFC437;">
            <div class="row">
                <div class="col">
                    <a href="#logopaedie" style="color: white;">Logopädie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#physiotherapie" style="color: white;">Physiotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#ergotherapie" style="color: white;">Ergotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
            </div>
        </div>
        <!-- 3 card -->
        <div class="card bg-primary mb-2">
            <img src="../assets/img/landing/ralphwithpatient.png" class="img-fluid">
            <div class="card-body text-center">
                <div class="card-title custom-text-003">🤷‍♂️ Was ist das VIAVITUM?</div>
                <div class="card-text custom-text-05">
                    Das Leben bietet mehr als Krankheit und Gesundheit - es findet dazwischen statt. Als Therapeuten können
                    wir in unseren Fachbereichen Großartiges leisten. Aber erst der interdisziplinäre Austausch ermöglicht
                    es uns spürbar mehr Lebensqualität für unsere Patienten zu erreichen. Das ist das Leitbild unserer
                    Praxis. Um das Potential unserer Patienten zu fördern, bieten wir vielfältige Therapien an.
                </div>
            </div>
        </div>
        <!-- -->
        <div class="card text-center custom-text-000 mb-2" style="background-color: #AFC437;">
            <div class="row">
                <div class="col">
                    <a href="#logopaedie">Logopädie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#physiotherapie">Physiotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
                <div class="col">
                    <a href="#ergotherapie">Ergotherapie<i class="fa fa-chevron-down"></i></a>
                </div>
            </div>
        </div>
        <!-- 4 card -->
        <div class="card text-center bg-cover bg-top mb-2">
            <div class="card-title custom-text-06 p-2"><span>WIR SIND DAS VIAVITUM</span>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/PEiU_1Ey-cU?si=LqdeAmgBJSK6i8ma"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <!-- 5 card -->
        <div id="logopaedie" class="card bg-cover bg-top mb-2"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/landing/logopaedie.png') + ')' }">
            <div class="card-title text-center" style="background-color: #AFC437;">
                <span class="card-title custom-text-07">DAS ERWARTET DICH BEI UNS ALS </span>
                <span class="card-title custom-text-07" style="color: #0D4360;">LOGOPÄD*IN</span>
            </div>
            <div class="card-text mx-auto custom-text-007 col-10">
                <p>
                    Als Therapiezentrum ist es uns wichtig alle Heilmittelerbringer:innen in unserer Einrichtung  zusammenzufassen. Daher suchen wir Verstärkung im Bereich der <span class="green-text">Logopädie</span>.
                </p>
                <p>
                    Um dein Potenzial entfalten zu können, bieten wir dir an deinen Arbeitsplatz frei nach deinen Wünschen zu gestalten. <span class="green-text">Deine Ideen</span> sind der Kompass um unser Therapiezentrum im Bereich der Logopädie weiterzubringen.
                </p>
                <p>
                    <span class="green-text">Ein extra Budget</span> unsererseits ist selbstverständlich eingeplant - so kannst du die Therapie mit Menschen jeglichen Alters selbst gestalten.
                </p>
            </div>
            <div class="mb-3 mx-auto">
                <knowing-button />
            </div>
        </div>
        <!-- 6 card -->
        <div id="physiotherapie" class="card bg-cover bg-top mb-2"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/landing/physiotherapie.png') + ')' }">
            <div class="card-title text-center" style="background-color: #0D4360;">
                <span class="card-title custom-text-07" style="color: #AFC437;">DAS ERWARTET DICH BEI UNS ALS </span>
                <span class="card-title custom-text-07" style="color: #FFFFFF;">PHYSIOTHERAPEUT*IN</span>
            </div>
            <div class="card-text mx-auto custom-text-007 col-10">
                <p>
                    Ob nun neu im Beruf oder bereits Profi, wenn du auch gern fernab der Bank <span class="green-text">aktiv in der Physiotherapie</span> arbeitest und nachhaltig deine Therapie weitergibst, hast du mit uns deine neue Lieblingspraxis gefunden.
                </p>
                <p>
                    Dein Know-how bringt uns gemeinsam weiter, da wir besonders im Bereich der <span class="green-text">orthopädischen</span> und auch <span class="green-text">neurologischen Rehabilitation</span> unseren Schwerpunkt setzen wollen.
                </p>
                <p>
                    Kaum ein <span class="green-text">Wunsch</span> bleibt bei uns offen, wenn es darum geht dich technisch und therapeutisch voranzubringen.
                </p>
            </div>
            <div class="mb-3 mx-auto">
                <knowing-button />
            </div>
        </div>
        <!-- 7 card -->
        <div id="ergotherapie" class="card bg-cover bg-top mb-2"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/landing/ergotherapist.png') + ')' }">
            <div class="card-title text-center" style="background-color: #AFC437;">
                <span class="card-title custom-text-07">DAS ERWARTET DICH BEI UNS ALS </span>
                <span class="card-title custom-text-07" style="color: #0D4360;">ERGOTHERAPEUT*IN</span>
            </div>
            <div class="card-text mx-auto custom-text-007 col-10">
                <p>
                    Im Bereich der <span class="green-text">Ergotherapie möchten wir wachsen</span>, d.h. wir planen unser Therapiespektrum wie auch unsere Räume zu erweitern.
                </p>
                <p>
                    Aus diesem Grund suchen wir Therapeut:innen, die vor allem Interesse im Bereich <span class="green-text">Neurofeedback</span> haben. Gerne übernehmen wir auch die Fortbildung wenn du Sie noch nicht hast.
                </p>
                <p>
                    Um deine Vorstellungen von Ergotherapie leben zu können, bieten wir dir die einmalige Chance, deine <span class="green-text">Räume selbst zu gestalten</span>, damit diese perfekt auf deine Therapie abgestimmt sind.
                </p>
            </div>
            <div class="mb-3 mx-auto">
                <knowing-button />
            </div>
        </div>
        <!-- 7 card -->
        <div class="card bg-cover bg-top mb-2"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/landing/masseur.png') + ')' }">
            <div class="card-title text-center" style="background-color: #0D4360;">
                <span class="card-title custom-text-07" style="color: #AFC437;">DAS ERWARTET DICH BEI UNS ALS </span>
                <span class="card-title custom-text-07" style="color: #FFFFFF;">MASSEUR*IN</span>
            </div>
            <div class="card-text mx-auto custom-text-007 col-10">
                <p>
                    Du gestaltest <span class="green-text">deinen Arbeitstag</span> gerne selbst?
                </p>
                <p>
                    Bei uns kommst voll und ganz auf deine Kosten. Ob in der Praxis, bei Einrichtungen oder Betrieben, wir bieten dir den Rahmen der <span class="green-text">zu dir und deinem Lifestyle</span> passt.
                </p>
                <p>
                    Abseits der Behandlungsbank bietet sich bei uns die Möglichkeit der betrieblichen Gesundheitsprävention in der <span class="green-text">du aktiv tätig</span> werden kannst.
                </p>
                <p>
                    <span class="green-text">Wertschätzung für deine Arbeit</span> findest du von uns nicht nur in der Bezahlung, sondern auch in der für dir gewünschten beruflichen Förderung.
                </p>
            </div>
            <div class="mb-3 mx-auto">
                <knowing-button />
            </div>
        </div>
        <!-- 9 card -->
        <div class="card bg-cover bg-top mb-2 custom-text-008">
            <div class="text-center custom-text-08">
                Warum es bei uns <span style="color: #AFC437;">spürbar</span><br>
            </div>
            <div class="text-center custom-text-08">
                <span style="color: #AFC437;">anders </span>ist!
            </div>
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/DxRqxadqf1M?si=X9SQ1zTV6nQftTHU"
                    allowfullscreen></iframe>
            </div>
        </div>
        <!-- 10 card -->
        <div class="card bg-primary mb-2">
            <div class="row align-items-center custom-row-01">
                <div class="col-3 text-center custom-icon-01">
                    <i class="fal fa-house"></i>
                </div>
                <div class="col custom-text-10">
                    Familienfreundliche flexible Arbeitszeit
                </div>
            </div>
            <div class="row align-items-center custom-row-01">
                <div class="col-3 text-center custom-icon-01">
                    <i class="fal fa-graduation-cap"></i>
                </div>
                <div class="col custom-text-10">
                    Fortbildungskostenübernahme mit frei
                </div>
            </div>
            <div class="row align-items-center custom-row-01">
                <div class="col-3 text-center custom-icon-01">
                    <i class="fal fa-head-side-brain"></i>
                </div>
                <div class="col custom-text-10">
                    Abwechslungsreicher Arbeitsplatz
                </div>
            </div>
            <div class="row align-items-center custom-row-01">
                <div class="col-3 text-center custom-icon-01">
                    <i class="fal fa-user-headset"></i>
                </div>
                <div class="col custom-text-10">
                    Rezeption / Support Anmeldung
                </div>
            </div>
            <div class="row align-items-center custom-row-01">
                <div class="col-3 text-center custom-icon-01">
                    <i class="fal fa-money-bill-wave"></i>
                </div>
                <div class="col custom-text-10">
                    mind. 3.100 € bei Vollzeit
                </div>
            </div>
        </div>
        <!-- 11 card -->
        <div class="card bg-primary mb-2">
            <img src="../assets/img/landing/allinonepc.png" class="img-fluid">
            <div class="mb-3 mx-auto mt-4">
                <knowing-button />
            </div>
        </div>
        <!-- 12 card -->
        <div class="card custom-bord-12">
            <div class="row align-items-center">
                <div class="col-3 text-center custom-icon-02">
                    <a href="https://www.google.com/maps?q=Heinrich-Brandt-Straße+25,91575+Windsbach" target="_blank">
                        <i class="fal fa-map-marker-alt"></i>
                    </a>
                </div>
                <div class="col-9 custom-text-09">
                    <a href="https://www.google.com/maps?q=Heinrich-Brandt-Straße+25,91575+Windsbach" target="_blank">
                        Heinrich-Brandt-Straße 25<br>
                        91575 Windsbach
                    </a>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-3 text-center custom-icon-02">
                    <a href="tel:098716579801">
                        <i class="fal fa-phone"></i>
                    </a>
                </div>
                <div class="col-9 custom-text-09">
                    <a href="tel:098716579801">
                        09871 - 657 98 01
                    </a>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-3 text-center custom-icon-02">
                    <a href="mailto:info@das-viavitum.de">
                        <i class="fal fa-envelope"></i>
                    </a>
                </div>
                <div class="col-9 custom-text-09">
                    <a href="mailto:info@das-viavitum.de">
                        info@das-viavitum.de<br>
                    </a>
                </div>
            </div>
            <div class="row mx-auto">
                <div class="col custom-icon-02">
                    <a class="social-link" href="https://www.instagram.com/das_viavitum/">
                        <i class="fab fa-instagram"></i>
                    </a>
                </div>
                <div class="col custom-icon-02">
                    <a class="social-link" href="https://facebook.com/people/Das-Viavitum/100083200307041">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                </div>
            </div>
            <div class="row mx-auto">
                <div class="col custom-icon-02">
                    <a class="social-link" href="https://www.tiktok.com/@das_viavitum">
                        <i class="fab fa-tiktok"></i>
                    </a>
                </div>
                <div class="col custom-icon-02">
                    <a class="social-link" href="https://www.youtube.com/channel/UC3AC5puRr9kXBsJv7KOr8XQ">
                        <i class="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KnowingButton from '../components/KnowingButton.vue';

export default {
    name: 'Landing',
    components: {
        KnowingButton
    },
    methods: {
    }
};
</script>

<style scoped>
.green-text {
    color: #AFC437;
}
.custom-text-000 {
    font-family: sans-serif;
    font-size: 3.5vw;
    font-weight: bold;
}

.custom-text {
    font-family: 'Canva Sans', sans-serif;
    font-size: 22px;
    font-weight: bold;
}

.custom-text-00 {
    text-align: center;
    font-weight: bold;
}

.custom-text-001 {
    font-family: 'Barlow Semi Condensed';
    font-size: 16vw;
}

.custom-text-002 {
    font-family: 'Encode Sans Semi Condensed';
    font-size: 6vw;
    margin-right: 8vw;
    margin-top: -5vw;
}

.custom-text-01 {
    font-family: 'Yellowtail';
    color: #AFC437;
    font-size: 10vw;
    margin-top: 2.5vw;
    white-space: nowrap;
}

.custom-text-02 {
    font-family: sans-serif;
    font-size: 4vw;
    margin: 2.5vw;
}

.custom-text-03 {
    font-family: sans-serif;
    font-size: 4vw;
    color: #AFC437;
    margin-bottom: 4vw;
}

.custom-text-04 {
    font-family: 'Canva Sans', sans-serif;
    font-size: 36px;
    font-weight: bold;
}

.custom-text-003 {
    font-family: sans-serif;
    font-size: 7vw;
    font-weight: bold;
}

.custom-text-05 {
    font-family: 'Canva Sans', sans-serif;
    font-size: 4vw;
    color: #FFFFFF;
}

.custom-text-06 {
    font-family: 'League Gothic', sans-serif;
    font-size: 12vw;
    color: #0D4360;
    border: 0.5vw solid #0D4360;
    border-radius: 2vw;
}

.custom-text-07 {
    font-family: 'League Gothic', sans-serif;
    font-size: 10vw;
}

.custom-text-007 {
    background-color: rgba(13, 67, 96, 0.7);
    color: white;
    border: solid #AFC437;
    border-radius: 3vw;
    padding: 2vw;
    margin: 2vw;
}

.custom-text-008 {
    background-color: white;
    border: solid #0D4360;
    border-radius: 3vw;
    padding: 2vw;
    margin: 1vw;
}

.custom-text-08 {
    font-family: 'League Gothic', sans-serif;
    font-size: 10vw;
    color: #0D4360;
    margin-top: -3vw;
    margin-bottom: -3vw;
}

.custom-text-09 {
    font-family: 'Canva Sans', sans-serif;
    font-size: 5vw;
    color: #0D4360;
}

.custom-text-10 {
    font-family: sans-serif;
    font-size: 4vw;
}

.custom-icon-01 {
    font-size: 7vw;
    color: #AFC437;
}

.custom-icon-02 {
    font-size: 8vw;
    color: #0D4360;
}

.custom-bord-12 {
    border: solid #AFC437;
    padding: 2vw;
}

.custom-row-01 {
    padding: 2vw;
}

.bg-primary {
    background-color: #0D4360;
    /* Du kannst die gewünschte Hintergrundfarbe hier festlegen */
    color: #fff;
    /* Textfarbe für bessere Lesbarkeit auf dunklem Hintergrund */
}

@media (min-width: 800px) {

    /* Ändern Sie die Schriftgröße für größere Bildschirme */
    .custom-text-000 {
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
    }

    .custom-text-001 {
        font-family: 'Barlow Semi Condensed';
        font-size: 130px;
    }

    .custom-text-002 {
        font-family: 'Encode Sans Semi Condensed';
        font-size: 40px;
        margin-right: 60px;
        margin-top: -40px;
    }

    .custom-text-01 {
        font-family: 'Yellowtail';
        color: #AFC437;
        font-size: 90px;
        margin-top: 30px;
    }

    .custom-text-02 {
        font-family: sans-serif;
        font-size: 35px;
        margin: 30px;
    }

    .custom-text-03 {
        font-family: sans-serif;
        font-size: 30px;
        color: #AFC437;
        margin-bottom: 30px;
    }

    .custom-text-003 {
        font-family: sans-serif;
        font-size: 60px;
        font-weight: bold;
    }

    .custom-text-05 {
        font-family: 'Canva Sans', sans-serif;
        font-size: 35px;
        color: #FFFFFF;
    }

    .custom-text-06 {
        font-family: 'League Gothic', sans-serif;
        font-size: 110px;
        color: #0D4360;
        border: 5px solid #0D4360;
        border-radius: 20px;
    }

    .custom-text-07 {
        font-family: 'League Gothic', sans-serif;
        font-size: 50px;
    }

    .custom-text-08 {
        font-family: 'League Gothic', sans-serif;
        font-size: 70px;
        color: #0D4360;
        margin-top: -20px;
        margin-bottom: -20px;
    }

    .custom-text-007 {
        background-color: rgba(13, 67, 96, 0.7);
        color: white;
        border: solid #AFC437;
        border-radius: 20px;
        padding: 10px;
        margin: 20px;
    }

    .custom-text-008 {
        background-color: white;
        border: solid #0D4360;
        border-radius: 20px;
        padding: 10px;
        margin: 10px;
    }

    .custom-text-09 {
        font-family: 'Canva Sans', sans-serif;
        font-size: 29px;
        color: #0D4360;
    }

    .custom-text-10 {
        font-family: sans-serif;
        font-size: 25px;
    }

    .custom-icon-01 {
        font-size: 50px;
        color: #AFC437;
    }

    .custom-icon-02 {
        font-size: 50px;
        color: #0D4360;
    }

    .custom-bord-12 {
        border: solid #AFC437;
        padding: 20px;
    }

    .custom-row-01 {
        padding: 20px;
    }

    .bg-primary {
        background-color: #0D4360;
        /* Du kannst die gewünschte Hintergrundfarbe hier festlegen */
        color: #fff;
        /* Textfarbe für bessere Lesbarkeit auf dunklem Hintergrund */
    }
}</style>
